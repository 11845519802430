<template>
    <div class="need-grade-container" v-lazy:background-image="require(`@/assets/images/needgrade_bg.png`)">
        <!-- <Loading :loading="loading" /> -->
        <div>
            <div class="main-title m-b-40">
                등급 심사 후<br />
                멤버로 등록됩니다
            </div>
            <div class="need-grade-desc m-b-16">우주메리는 아무나 가입할 수 없습니다.</div>
            <div class="need-grade-desc m-b-16">
                출신 대학, 경제력, 외모를 모두 갖춘<br />
                <span class="need-grade-emp"> 상위 30%만 멤버로 등록이 가능 </span>
                합니다.
            </div>
            <div class="need-grade-desc m-b-40">등급 심사 후 이용해주세요!</div>
            <button class="get-grade" @click="getGrade()">등급 심사 받기</button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NeedGradeTest',

    data: () => ({
        // loading: false,
    }),
    methods: {
        getGrade() {
            const test = 'https://staging.skymarry.com/'
            const prod = 'https://wouldyoumarry.com/'
            // const prod = 'https://wouldyoumarry.com/'
            const Url = process.env.NODE_ENV === 'test' ? test : prod
            // window.open(Url + 'grade')

            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: Url + 'grade',
            })
        },
        back() {
            this.$stackRouter.pop()
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
}
</script>
<style scoped lang="scss">
.need-grade-container {
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
    color: #111111;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -1px;
    }

    .need-grade-desc {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.5px;
        .need-grade-emp {
            font-weight: bold;
        }
    }
    .get-grade {
        width: 200px;
        background: #070a0e;
        border-radius: 8px;
        text-align: center;
        height: 48px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        border: none;
    }
}
</style>
